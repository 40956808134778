/* eslint-disable react/prop-types */
/**
 * Module dependencies
 */
import React from 'react';

import { Style } from 'nordic/style';
import { MeliGA } from 'nordic/analytics/meli-ga';
import { Hotjar } from 'nordic/hotjar';
import Script from 'nordic/script';

import Metadata from '../../../../../components/metadata';
import GoogleTagManager from '../../../../../components/google-tag-manager';
import useHotjarActionsByCookie from '../../../../../hooks/use-hotjar-actions-by-cookie';

/**
 * View Component
 */
const Category = ({ children, cookies, deviceType, initialState, hotjar }) => {
  useHotjarActionsByCookie(hotjar);

  return (
    <>
      <Style href={`search.${deviceType}.cat.css`} critical={deviceType === 'mobile'} />
      <Metadata {...initialState.seo} {...initialState.canonical_info} />
      {children}
      <MeliGA {...initialState.analytics_track} />
      <Hotjar {...hotjar} />
      <GoogleTagManager
        results={initialState.results}
        platform="CAT"
        cookies={cookies}
        queryInitialState={initialState.query}
      />
      <Script priority={1} on="now">
        {
          // Registrar el Tag de la página
          'window.PAGE_TYPE = "categories";'
        }
      </Script>
    </>
  );
};

/**
 * Inject i18n context as props into View.
 */
export default Category;
