import React from 'react';

import { useSearch } from '../../../hooks/context';
import { useStaticProps } from '../../../components/context/static-props';

const namespace = 'ui-category-trends-header';

/**
 * @typedef {Object} CategoriesHeaderProps
 * @property {Object} seo - Información SEO asociada al encabezado.
 * @property {string} seo.h1 - El título principal para SEO.
 * @property {Object} header - Información del encabezado.
 * @property {string} header.title - El título del encabezado.
 * @property {string} header.subtitle - El subtítulo del encabezado.
 * @property {string} deviceType - El tipo de dispositivo (por ejemplo, "desktop" o "mobile").
 */

/**
 * Componente que renderiza el encabezado de categorías con título y subtítulo dinámicos.
 *
 * @returns {React.ReactNode} Un nodo React que contiene el encabezado de categorías.
 */
const CategoriesHeader = () => {
  const { seo, header } = useSearch();
  const { deviceType } = useStaticProps();

  const categoryName = header?.subtitle || seo.h1;

  return (
    <div className={`${namespace}-title-container ${namespace}-${deviceType}-title-container`}>
      <h1 className={`${namespace}-title ${namespace}-${deviceType}-title`}>
        {header?.title}
        <b className={`${namespace}-title-category-name`}>{categoryName}</b>
      </h1>
    </div>
  );
};

export default CategoriesHeader;
