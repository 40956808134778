import { useSearch } from '../../hooks/context';
import { useUser } from '../../components/context/user';
import { useStaticProps } from '../../components/context/static-props';
import useCategoryTrendCarouselCollection from './hooks/useCategoryTrendCarouselCollection';

const CategoryTrendCarouselCollection = () => {
  const { results } = useSearch();
  const { loggedIn } = useUser();
  const { deviceType, country } = useStaticProps();
  const items = useCategoryTrendCarouselCollection({ results, loggedIn, deviceType, country });

  if (!items) {
    return null;
  }

  return Array.from(items);
};

export default CategoryTrendCarouselCollection;
