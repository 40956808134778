import React from 'react';

import { loadable } from 'nordic/lazy';

import useExhibitorHeader from './use-exhibitor-pi';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const ChunkExhibitor = () => {
  const exhibitorProps = useExhibitorHeader();
  const ExhibitorHeader = loadable(async () => import('./desktop.pi'));

  return exhibitorProps ? <ExhibitorHeader {...exhibitorProps} /> : null;
};

export default withComponentHandler(ChunkExhibitor, { componentName: 'ChunkExhibitor' });
