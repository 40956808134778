import { useMemo, useCallback } from 'react';

import { renderDesktopContent, trendsList } from '../utils/index';

const useCategoryTrendCarouselCollection = ({ results, loggedIn, deviceType, country }) => {
  const getTrendProps = useCallback((trendId) => results.find((x) => x.id === trendId), [results]);
  const layoutItemsList = useMemo(
    () => ({
      mostWantedTrendsProps: getTrendProps(trendsList.MOST_WANTED_TREND),
      higherGrowthTrendsProps: getTrendProps(trendsList.HIGHER_GROWTH_TREND),
      mostPopularTrendsProps: getTrendProps(trendsList.MOST_POPULAR_TREND),
      consolidatedTrendsProps: getTrendProps(trendsList.CONSOLIDATED_TRENDS),
      manualTrendsProps: getTrendProps(trendsList.MANUAL_TRENDS),
    }),
    [getTrendProps],
  );

  const dependantLayoutItemsList = {
    bestSellersItemsProps: getTrendProps(trendsList.BEST_SELLERS_POLYCARDS),
    recommendedItemsProps: getTrendProps(trendsList.RECOMMENDED_ITEMS_POLYCARDS),
  };

  const otherFilledTrendsProps = getTrendProps(trendsList.OTHER_FILLED_TRENDS);

  const hasCarousel =
    layoutItemsList.mostWantedTrendsProps ||
    layoutItemsList.higherGrowthTrendsProps ||
    layoutItemsList.mostPopularTrendsProps;

  const layoutItems = Object.keys(layoutItemsList).map((key) => renderDesktopContent({ props: layoutItemsList[key] }));

  const dependantLayoutItems = Object.keys(dependantLayoutItemsList).map((key) =>
    renderDesktopContent({
      props: dependantLayoutItemsList[key],
      hasCarousel: false,
      preload: loggedIn,
      deviceType,
      countryConfig: country,
    }),
  );

  const bottomLayoutItems = [renderDesktopContent({ props: otherFilledTrendsProps, hasCarousel })];

  if (!loggedIn) {
    return [...layoutItems, ...dependantLayoutItems, ...bottomLayoutItems];
  }

  return [...dependantLayoutItems, ...layoutItems, ...bottomLayoutItems];
};

export default useCategoryTrendCarouselCollection;
