import { loadable } from 'nordic/lazy';
import cloneDeep from 'lodash/cloneDeep';

/* istanbul ignore next */
const CategoryTrendCarousel = loadable(async () =>
  import(/* webpackChunkName: "chunk-category-trend-carousel" */ '../category-trend-carousel'),
);

/* istanbul ignore next */
const CategoryTrendContainer = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-category-trend-container" */ '../../category-trend-container/category-trend-container'
  ),
);

/* istanbul ignore next */
const ContainerItems = loadable(async () =>
  import(/* webpackChunkName: "chunk-container-items" */ '../../../components/grid-layout/container-items.desktop'),
);

const trendsList = {
  HIGHER_GROWTH_TREND: 'HIGHER_GROWTH',
  MOST_WANTED_TREND: 'MOST_WANTED',
  MOST_POPULAR_TREND: 'MOST_POPULAR',
  MANUAL_TRENDS: 'MANUAL_TRENDS',
  CONSOLIDATED_TRENDS: 'CONSOLIDATED_TRENDS',
  OTHER_FILLED_TRENDS: 'OTHER_FILLED_TRENDS',
  BEST_SELLERS_POLYCARDS: 'BEST_SELLER_POLYCARDS',
  RECOMMENDED_ITEMS_POLYCARDS: 'RECOMMENDED_ITEMS_POLYCARDS',
};

const renderDesktopContent = ({ props, hasCarousel, preload, deviceType, countryConfig }) => {
  if (!props) {
    return null;
  }

  const { id, trends_categories: trends } = props;
  const trends_categories = trends ? cloneDeep(trends) : trends;

  switch (id) {
    case trendsList.HIGHER_GROWTH_TREND:
    case trendsList.MOST_WANTED_TREND:
    case trendsList.MOST_POPULAR_TREND:
    case trendsList.MANUAL_TRENDS:
      return <CategoryTrendCarousel key={`${id}-trend`} trends_categories={trends_categories} />;
    case trendsList.CONSOLIDATED_TRENDS:
      return <CategoryTrendContainer key={`${id}-trend`} trends_categories={trends_categories} />;
    case trendsList.OTHER_FILLED_TRENDS:
      return (
        <CategoryTrendContainer key={`${id}-trend`} trends_categories={trends_categories} hasCarousel={hasCarousel} />
      );
    case trendsList.BEST_SELLERS_POLYCARDS:
    case trendsList.RECOMMENDED_ITEMS_POLYCARDS:
      return (
        <ContainerItems
          key={`${id}-trend`}
          component={trends_categories}
          preload={preload}
          deviceType={deviceType}
          countryConfig={countryConfig}
          preloadNumImage={3}
        />
      );

    default:
      return null;
  }
};

export { trendsList, renderDesktopContent };
